import { _mock } from './_mock';

// ----------------------------------------------------------------------

export const _categories = [
  { label: 'Marketing', path: '' },
  { label: 'Community', path: '' },
  { label: 'Tutorials', path: '' },
  { label: 'Business', path: '' },
  { label: 'Management', path: '' },
];

// ----------------------------------------------------------------------

export const _testimonials = [
  {
    id: "omar_shareef_1",
    name: "Omar Shareef",
    role: "Co-Founder, Co-Chair",
    avatarUrl: "/assets/mentor-pics/omar_shareef.jpg",
    ratingNumber: 5,
    review:
      'I grew up most of my life thinking that deen and duniya are entirely disparate, that religiosity and material success cannot go hand in hand, that one ineherently takes you away from the other. It took many years to realize that this need not be the case. Rather, Islam should be the lens by which you can achieve true success, and without grounding the material in Islam, anything to be gained in this world is vacuous. But, I did not come to this realization on my own. I had mentors and role models who inspired me and helped facilitate this understanding. And my hope is that Maroof Mentoring will do the same for other students.',
  },
  {
    id: "sana_shareef_1",
    name: "Sana Shareef",
    role: "Co-Founder, Co-Chair",
    avatarUrl: "/assets/mentor-pics/sana_shareef.jpg",
    ratingNumber: 5,
    review:
      'Islam has a rich history of rigorous intellectual inquiry.  In a time of vast technological, social and political change, reviving this intellectual tradition will allow for the Muslim youth to drive purpose-driven impact, while staying grounded in Islamic values. Instilling this ethos in the next generation of Muslims can only happen through robust systems of mentorship. We hope to provide that here at Maroof.',
  },
]


// [...Array(8)].map((_, index) => ({
//   id: _mock.id(index),
//   name: _mock.fullName(index),
//   role: _mock.role(index),
//   avatarUrl: _mock.image.avatar(index),
//   createdAt: _mock.time(index),
//   ratingNumber: 5,
//   review:
//     'I grew up most of my life thinking that deen and duniya are entirely disparate, that religiosity and material success cannot go hand in hand, that one ineherently takes you away from the other. It took many years to realize that this need not be the case. Rather, Islam should be the lens by which you can achieve true success, and without grounding the material in Islam, anything to be gained in this world is vacuous. But, I did not come to this realization on my own. I had mentors and role models who inspired me and helped facilitate this understanding. And my hope is that Maroof Mentoring will do the same for other students.',
// }));

// ----------------------------------------------------------------------

export const _socials = [
  {
    value: 'facebook',
    label: 'FaceBook',
    icon: 'carbon:logo-facebook',
    color: '#1877F2',
  },
  {
    value: 'instagram',
    label: 'Instagram',
    icon: 'carbon:logo-instagram',
    color: '#E02D69',
  },
  {
    value: 'linkedin',
    label: 'Linkedin',
    icon: 'carbon:logo-linkedin',
    color: '#007EBB',
  },
  {
    value: 'twitter',
    label: 'Twitter',
    icon: 'carbon:logo-twitter',
    color: '#00AAEC',
  },
];

// ----------------------------------------------------------------------

const LAT_LONG = [
  [33, 65],
  [-12.5, 18.5],
  [20.96, 26.27],
];

export const _offices = ['Jordan', 'Canada', 'Portugal'].map((office, index) => ({
  id: _mock.id(index),
  country: office,
  address: _mock.fullAddress(index),
  phoneNumber: _mock.phoneNumber(index),
  email: _mock.email(index),
  photo: _mock.image.travel(index + 4),
  latlng: LAT_LONG[index],
}));

// ----------------------------------------------------------------------

const BRANDS_NAME = [
  'airbnb',
  'dropbox',
  'facebook',
  'google',
  'heroku',
  'lenovo',
  'microsoft',
  'netflix',
  'slack',
  'spotify',
  'tripadvisor',
  'vimeo',
];

export const _brands = BRANDS_NAME.map((brand, index) => ({
  id: _mock.id(index),
  name: brand,
  image: `/assets/logo/${brand}.svg`,
}));

export const _brandsColor = BRANDS_NAME.map((brand, index) => ({
  id: _mock.id(index),
  name: brand,
  image: `/assets/logo/${brand}_original.svg`,
}));

// ----------------------------------------------------------------------

export const _faqs = [
  'Sed augue ipsum, egestas nec, vestibulum et',
  'alesuada adipiscing, dui vestibulum suscipit nulla quis orci.',
  'Ut varius tincidunt libero',
  'In ut quam vitae odio lacinia tincidunt.',
  'Fusce vel dui Morbi nec metus.',
].map((question, index) => ({
  id: _mock.id(index),
  question,
  answer:
    'Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I appreciate there attitude and approach.',
}));

export const _faqsSupport = [
  `[Covid] Seasonal Shopping Guide`,
  'I Want To Check Where My Order Is Delivered',
  '[Shipping Information] How To Contact The Shipping Unit/Look Up Shipping Information/Delivery Exchange?',
  '[Seller] Start Selling With Shopee',
  'Why Is My Account Locked/Limited?',
  'Free Shipping Code User Guide (Freeship Code)',
  'How To Buy / Order On Shopee App',
  `Why I Didn't Receive the Verification Code (OTP)?`,
  `Frequently Asked Questions About Product Reviews / Comments`,
  `How to Login Shopee Account When Forgot/Lost Password`,
].map((question, index) => ({
  id: _mock.id(index),
  question,
  answer:
    'Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I appreciate there attitude and approach.',
}));
