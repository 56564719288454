// ----------------------------------------------------------------------

export const paths = {
  // E-learning
  eLearning: {
    root: '/e-learning',
    courses: '/e-learning/courses',
    course: `/e-learning/course`,
    posts: '/e-learning/posts',
    post: `/e-learning/post`,
    about: '/e-learning/about',
    contact: '/e-learning/contact',
  },
  // Auth
  loginCover: '/auth/login-cover',
  loginBackground: '/auth/login-background',
  loginIllustration: '/auth/login-illustration',
  registerCover: '/auth/register-cover',
  registerBackground: '/auth/register-background',
  registerIllustration: '/auth/register-illustration',
  forgotPassword: '/auth/forgot-password',
  verify: '/auth/verify',
  // Common
  maintenance: '/maintenance',
  comingsoon: '/coming-soon',
  pricing01: '/pricing-01',
  pricing02: '/pricing-02',
  payment: '/payment',
  support: '/support',
  page404: '/404',
  page500: '/500',
  // Others
  pages: '/pages',
  docs: 'https://zone-docs.vercel.app',
  license: 'https://material-ui.com/store/license/#i-standard-license',
  menteeForm: 'https://airtable.com/appcBvAWD2rk2AykS/shrFxq8Sm0mJu4Nig',
  minimalStore: 'https://material-ui.com/store/items/minimal-dashboard',
  zoneStore: 'https://mui.com/store/items/zone-landing-page/',
  figmaPreview:
    'https://www.figma.com/file/X6OyiGHF8dnTk3aT38P0oN/%5BPreview%5D-Zone_Web.30.03.23?node-id=0-1',
};
