import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';

import Logo from 'src/components/logo'
import { bgBlur } from 'src/theme/css';
import { paths } from 'src/routes/paths';
import { useResponsive } from 'src/hooks/use-responsive';
import { useOffSetTop } from 'src/hooks/use-off-set-top';

import { HEADER } from '../config-layout';
import HeaderShadow from '../common/header-shadow';

import NavMobile from './nav/mobile';
import { navConfig } from './config-navigation';

// ----------------------------------------------------------------------

export default function Header({ headerOnDark }) {
  const theme = useTheme();

  const offset = useOffSetTop();

  const mdUp = useResponsive('up', 'md');

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(offset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: 'text.primary',
            height: {
              md: HEADER.H_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container
          sx={{ height: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box sx={{ lineHeight: 0, position: 'relative' }}>

            <Logo />

          {/* <svg 
            id="Layer_1" 
            width="50%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 236.51 78.83">
              <defs>
                <style>
                {".cls-1 {fill:#237b3c; fill-rule:evenodd; stroke-width:0px;}"}
                </style>
              </defs>
              <path className="cls-1" d="m225.24,0h11.26v78.83h-33.79v-22.52h-22.52v22.52h-11.26v-22.52h-22.52v22.52H0v-11.26h135.15V0h11.26v45.05h22.52v-11.26h-11.26V0h33.79v33.79h-11.26v11.26h22.52s11.26,0,11.26,0h0v22.52h11.26v-33.79h-22.52V0h22.52ZM0,0v11.26h0v45.05H0s11.26,0,11.26,0h0s56.31,0,56.31,0h11.26V0h-33.79v33.79h0s11.26,0,11.26,0h0s11.26,0,11.26,0v11.26H11.26V11.26h22.52V0H11.26S0,0,0,0H0s0,0,0,0h0Zm22.52,22.52v11.26h11.26v-11.26h-11.26Zm78.83,0h11.26v-11.26h-11.26v11.26h0Zm11.26,11.26v11.26h-22.52v11.26h33.79V0h-33.79v33.79h22.52Zm-45.05-22.52h-11.26v11.26h11.26v-11.26h0Zm146.41,0v11.26h11.26v-11.26h-11.26Zm-45.05,11.26h11.26v-11.26h-11.26v11.26Z"/>
            </svg> */}

          </Box>

          {/* {mdUp && <NavDesktop data={navConfig} />} */}

          <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
            {/* <Stack spacing={1} direction="row" alignItems="center">
              <Searchbar />

              <SettingsButton />
            </Stack> */}

            {mdUp && (
              <Button
                variant="contained"
                color="inherit"
                href={paths.menteeForm}
                target="_blank"
                rel="noopener"
              >
                Sign up
              </Button>
            )}
          </Stack>

          {!mdUp && <NavMobile data={navConfig} />}
        </Container>
      </Toolbar>

      {offset && <HeaderShadow />}
    </AppBar>
  );
}

Header.propTypes = {
  headerOnDark: PropTypes.bool,
};
