import { memo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

function Logo({ single = false, sx }) {
  const theme = useTheme();

  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const singleLogo = (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="100%"
  //     height="100%"
  //     fill="none"
  //     viewBox="0 0 512 512"
  //   >
  //     <ellipse cx="405.143" cy="338.571" fill={PRIMARY_MAIN} rx="82.857" ry="82.857" />
  //     <path
  //       fill="currentColor"
  //       d="M114.742 355.332H256v66.097H24v-61.376l140.323-203.956H24V90h232v61.376L114.742 355.332z"
  //     />
  //   </svg>
  // );

  const fullLogo = (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg "
    //   width="100%"
    //   height="100%"
    //   fill="none"
    //   viewBox="0 0 1080 288"
    // >
    //   <ellipse cx="996" cy="204" fill={PRIMARY_MAIN} rx="60" ry="60" />
    //   <path
    //     fill="currentColor"
    //     d="M712 264h-58.815l-98.37-148.034V264H496V24h58.815l98.37 148.718V24H712v240zM801.265 70.838v48.547H880v45.128h-78.735v52.649H888V264H744V24h144v46.838h-86.735zM344.333 264c-22 0-42.222-5.118-60.666-15.355-18.223-10.236-32.778-24.478-43.667-42.726-10.667-18.47-16-39.165-16-62.086s5.333-43.505 16-61.752c10.889-18.248 25.444-32.49 43.667-42.726C302.111 29.118 322.333 24 344.333 24s42.111 5.118 60.334 15.355C423.111 49.59 437.556 63.833 448 82.08c10.667 18.247 16 38.831 16 61.752s-5.333 43.616-16 62.086c-10.667 18.248-25.111 32.49-43.333 42.726C386.444 258.882 366.333 264 344.333 264zm0-52.072c18.667 0 33.556-6.231 44.667-18.693 11.333-12.462 17-28.929 17-49.402 0-20.695-5.667-37.163-17-49.402-11.111-12.462-26-18.692-44.667-18.692-18.889 0-34 6.12-45.333 18.358-11.111 12.24-16.667 28.818-16.667 49.736 0 20.696 5.556 37.274 16.667 49.736 11.333 12.239 26.444 18.359 45.333 18.359zM89.71 216.137H192V264H24v-44.444L125.613 71.863H24V24h168v44.444L89.71 216.137z"
    //   />
    // </svg>

    // viewBox="0 0 236.51 164.14">

    <svg 
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    width="100%"
    height="100%"
    viewBox="0 0 236.51 164.14">
      <defs>
      <style>{
        '.path-1 {fill:#237b3c; fill-rule:evenodd; stroke-width:0px;} .rect-1 {fill:#237b3c; stroke-width:0px;}, .cls-2 {fill: #237b3c; stroke-width: 0px;}'}
      </style>
    </defs>
    <path className="path-1" d="m35.84,100.4l-17.7,24.46L.42,100.4H0v37.95h7.43v-16.13l10.69,15.51h.04l10.67-15.51v16.13h7.43v-37.95h-.41Zm35.58,37.95h8.06l-17.7-37.95h-.23l-17.67,37.95h8.03l2.37-5.18h14.8l2.34,5.18h0Zm-14.25-11.51l4.51-9.9,4.5,9.9h-9.01Zm58.29,11.51l-10.5-14.71c3.97-1.4,6.83-5.56,6.83-10.31,0-6.66-5.67-11.98-12.59-11.98h-12.06l.02,37h7.41v-17.49l11.8,17.49h9.08Zm-20.88-29.63h4.77c2.5,0,4.77,2.02,4.77,4.66s-2.25,4.68-4.77,4.66l-4.77-.02v-9.3h0Zm45.95,30.33c10.61,0,19.28-8.61,19.28-19.15s-8.67-19.3-19.26-19.3-19.21,8.68-19.21,19.3,8.62,19.15,19.19,19.15h0Zm0-7.51c-6.45,0-11.71-5.26-11.71-11.69s5.26-11.75,11.71-11.75,11.76,5.28,11.76,11.75-5.28,11.69-11.76,11.69h0Zm45.64,7.51c10.61,0,19.28-8.61,19.28-19.15s-8.67-19.3-19.26-19.3-19.21,8.68-19.21,19.3,8.62,19.15,19.19,19.15h0Zm0-7.51c-6.45,0-11.71-5.26-11.71-11.69s5.26-11.75,11.71-11.75,11.76,5.28,11.76,11.75-5.28,11.69-11.76,11.69h0Zm50.32-22.87v-7.41h-22.31v37.14h7.43v-14.87h12.77v-7.41h-12.77v-7.45h14.88Z"/>
    <path className="path-1" d="m52.8,152.65l-5.26,7.26-5.26-7.26h-.12v11.27h2.21v-4.79l3.17,4.61h.01l3.17-4.61v4.79h2.21v-11.27h-.12Zm16.85,2.46v-2.17h-6.62v10.99h6.62v-2.19h-4.42v-2.28h3.79v-2.2h-3.79v-2.14h4.42Zm16.45-2.2v6.16l-6.51-6.33h-.11v11.21h2.21v-6.16l6.53,6.35h.1v-11.23h-2.22Zm19.28.03h-7.62v2.19h2.71v8.8h2.22v-8.8h2.7v-2.19h0Zm14.13,11.2c3.15,0,5.73-2.56,5.73-5.69s-2.57-5.73-5.72-5.73-5.7,2.58-5.7,5.73,2.56,5.69,5.7,5.69h0Zm0-2.23c-1.91,0-3.48-1.56-3.48-3.47s1.56-3.49,3.48-3.49,3.49,1.57,3.49,3.49-1.57,3.47-3.49,3.47h0Zm23.77,2.02l-3.12-4.37c1.18-.42,2.03-1.65,2.03-3.06,0-1.98-1.68-3.56-3.74-3.56h-3.58v10.99h2.21v-5.2l3.5,5.2h2.7Zm-6.2-8.8h1.42c.74,0,1.42.6,1.42,1.39s-.67,1.39-1.42,1.38h-1.42v-2.77h0Zm15.44,8.8h2.21v-10.99h-2.21v10.99h0Zm18.94-11.02v6.16l-6.51-6.33h-.11v11.21h2.21v-6.16l6.53,6.35h.1v-11.23h-2.22Zm22.82,4.85h-5.17v2.04h2.85c-.07,1.33-1.2,2.14-2.77,2.14-2.15,0-3.64-1.58-3.64-3.49,0-2.05,1.73-3.5,3.43-3.5.98,0,1.97.48,2.83,1.32l1.22-1.83c-1.08-1.05-2.6-1.7-4.02-1.72-3.15,0-5.71,2.58-5.71,5.73s2.55,5.71,5.79,5.71c2.87,0,5.24-2.01,5.24-5.47,0-.3-.02-.61-.06-.93Z"/>
    <rect className="rect-1" x="201.99" y="157.3" width="32.69" height="2.58"/>
    <rect className="rect-1" x="1.83" y="157.3" width="32.7" height="2.58"/>
    <path className="path-1" d="m225.24,0h11.26v78.83h-33.79v-22.52h-22.52v22.52h-11.26v-22.52h-22.52v22.52H0v-11.26h135.15V0h11.26v45.05h22.52v-11.26h-11.26V0h33.79v33.79h-11.26v11.26h22.52s11.26,0,11.26,0h0v22.52h11.26v-33.79h-22.52V0h22.52ZM0,0v11.26h0v45.05H0s11.26,0,11.26,0h0s56.31,0,56.31,0h11.26V0h-33.79v33.79h0s11.26,0,11.26,0h0s11.26,0,11.26,0v11.26H11.26V11.26h22.52V0H11.26S0,0,0,0H0s0,0,0,0h0Zm22.52,22.52v11.26h11.26v-11.26h-11.26Zm78.83,0h11.26v-11.26h-11.26v11.26h0Zm11.26,11.26v11.26h-22.52v11.26h33.79V0h-33.79v33.79h22.52Zm-45.05-22.52h-11.26v11.26h11.26v-11.26h0Zm146.41,0v11.26h11.26v-11.26h-11.26Zm-45.05,11.26h11.26v-11.26h-11.26v11.26Z"/>
    </svg>

//     <svg id="Layer_2"       
//     width="100%"
//     height="100%"
//     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 200">
//   <defs>
//     <style>{
//       '.cls-1 {fill-rule: evenodd;} .cls-1, .cls-2 {fill: #237b3c; stroke-width: 0px;}'}
//     </style>
//   </defs>
//   <g id="Layer_1-2" data-name="Layer 1">
//     <g>
//       <path className="cls-1" d="m197.67,0h12.35v61.77h-98.84v12.35h98.84v12.35h-111.19V0h12.36v49.42h24.71v-12.36h-12.35V0h37.06v37.06h-12.35v12.36h49.42v-12.36h-24.71V0h24.71ZM0,0h12.35v12.35H0V0h0Zm0,24.71v12.35h24.71v12.36H0v37.06h86.48V0h-37.06v37.06h24.71v37.06h-37.06V24.71H0Zm12.35,37.06h12.35v12.35h-12.35v-12.35h0ZM74.13,12.35v12.36h-12.35v-12.36h12.35Zm111.19,0v12.36h12.35v-12.36h-12.35Zm-49.42,12.36h12.35v-12.36h-12.35v12.36h0ZM24.71,0h12.36v12.35h-12.36V0h0Zm24.71,49.42h12.35v12.35h-12.35v-12.35Z"/>
//       <path className="cls-1" d="m37.75,104.99l-18.65,25.76L.44,104.99h-.44v39.97h7.83v-16.99l11.26,16.34h.04l11.23-16.34v16.99h7.83v-39.97h-.44Zm37.48,39.97h8.49l-18.64-39.97h-.24l-18.61,39.97h8.46l2.49-5.46h15.59l2.47,5.46h0Zm-15.01-12.13l4.75-10.43,4.74,10.43h-9.49Zm61.4,12.13l-11.06-15.49c4.18-1.48,7.2-5.85,7.2-10.86,0-7.02-5.97-12.62-13.26-12.62h-12.7l.02,38.97h7.81v-18.42l12.42,18.42h9.57Zm-21.99-31.21h5.03c2.63,0,5.03,2.13,5.03,4.91s-2.37,4.93-5.03,4.9l-5.03-.02v-9.79h0Zm29.82,31.21h7.83v-38.98h-7.83v38.98h0Zm42.24-31.26v-7.81h-23.49v39.12h7.83v-15.66h13.45v-7.81h-13.45v-7.84h15.67Zm29.85,31.26h8.49l-18.64-39.97h-.24l-18.61,39.97h8.46l2.49-5.46h15.59l2.47,5.46h0Zm-15.01-12.13l4.75-10.43,4.74,10.43h-9.49Z"/>
//       <path className="cls-1" d="m46.89,157.78l-4.67,6.45-4.67-6.45h-.11v10.01h1.96v-4.25l2.82,4.09h.01l2.81-4.09v4.25h1.96v-10.01h-.11Zm14.97,2.18v-1.93h-5.88v9.76h5.88v-1.94h-3.92v-2.03h3.37v-1.96h-3.37v-1.9h3.92Zm14.6-1.96v5.47l-5.78-5.62h-.1v9.95h1.96v-5.47l5.8,5.64h.09v-9.97h-1.97Zm17.12.03h-6.77v1.94h2.4v7.82h1.97v-7.82h2.4v-1.94h0Zm12.55,9.94c2.8,0,5.08-2.27,5.08-5.05s-2.29-5.09-5.08-5.09-5.07,2.29-5.07,5.09,2.27,5.05,5.06,5.05h0Zm0-1.98c-1.7,0-3.09-1.39-3.09-3.08s1.39-3.1,3.09-3.1,3.1,1.39,3.1,3.1-1.39,3.08-3.1,3.08h0Zm21.11,1.8l-2.77-3.88c1.05-.37,1.8-1.47,1.8-2.72,0-1.76-1.5-3.16-3.32-3.16h-3.18v9.76h1.96v-4.61l3.11,4.61h2.4Zm-5.51-7.82h1.26c.66,0,1.26.53,1.26,1.23s-.59,1.23-1.26,1.23h-1.26v-2.46h0Zm13.71,7.82h1.96v-9.76h-1.96v9.76h0Zm16.82-9.78v5.47l-5.78-5.62h-.1v9.95h1.96v-5.47l5.8,5.64h.09v-9.97h-1.97Zm20.27,4.3h-4.59v1.81h2.53c-.07,1.18-1.07,1.9-2.46,1.9-1.91,0-3.23-1.4-3.23-3.1,0-1.82,1.53-3.11,3.05-3.11.87,0,1.75.42,2.51,1.17l1.09-1.63c-.96-.93-2.31-1.51-3.57-1.52-2.79,0-5.07,2.29-5.07,5.09s2.26,5.07,5.14,5.07c2.55,0,4.65-1.78,4.65-4.86,0-.27-.02-.54-.05-.82Z"/>
//       <rect className="cls-2" x="179.37" y="161.91" width="29.03" height="2.29"/>
//       <rect className="cls-2" x="1.63" y="161.91" width="29.03" height="2.29"/>
//     </g>
//   </g>
// </svg>
  );

  return (
    <Link
      component={RouterLink}
      href="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          width: single ? 64 : 75,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        }}
      >
        {fullLogo}
        {/* {single ? singleLogo : fullLogo} */}
      </Box>
    </Link>
  );
}

Logo.propTypes = {
  single: PropTypes.bool,
  sx: PropTypes.object,
};

export default memo(Logo);
